import React from "react"
import Inline from "./inline"

const Actions = ({ children, ...restProps }) => {
  return (
    <Inline space={6} {...restProps}>
      {children}
    </Inline>
  )
}

export default Actions
