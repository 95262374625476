import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../components/breadcrumb"
import Divider from "../../components/divider"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Box from "../../components/box"
import Image from "../../components/image"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import Paragraph from "../../components/paragraph"
import TextLink from "../../components/textlink"
import Actions from "../../components/actions"
import Button from "../../components/button"
import AttributionDauerausstellung from "../../content/shared/attribution-dauerausstellung"
import Constrain from "../../components/constrain"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query AusstellungenDauerausstellungenQuery {
      intro: file(relativePath: { eq: "ausstellungen/intro.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 480, height: 360, layout: CONSTRAINED)
        }
      }
      wirkung: file(relativePath: { eq: "ausstellungen/wirkung.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 480, height: 360, layout: CONSTRAINED)
        }
      }
      sprachlabor: file(relativePath: { eq: "ausstellungen/sprachlabor.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 480, height: 360, layout: CONSTRAINED)
        }
      }
      medienstation: file(
        relativePath: { eq: "ausstellungen/medienstation.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, height: 360, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="dauerausstellung">
      <Seo
        title="Dauerausstellung"
        description="Die Dauerausstellung im Hölderlinturm widmet sich Hölderlins Tübinger Studienjahren und seiner zweiten Lebenshälfte im Turm. Sie zeigt seine Arbeit an der Sprache und dem Rhythmus seiner Gedichte, lädt zum Ausprobieren und Experimentieren ein und macht Hölderlins Sprachkunst sinnlich erfahrbar."
      />

      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Ausstellungen",
              link: "/ausstellungen",
            },
            {
              title: "Dauerausstellung",
              link: "/ausstellungen/dauerausstellung",
            },
          ]}
        />
        <Stack space={[12, 24]}>
          <PageTitle>Dauerausstellung</PageTitle>

          <Stack>
            <Heading as="h2" level={2}>
              Hölderlin in Tübingen
            </Heading>
            <Grid columns={[1, 1, 2]} reverse={true}>
              <Image
                image={data.intro.childImageSharp.gatsbyImageData}
                alt="Bild des ersten Raumes der Dauerausstellung: Das Gedicht ›Die Linien des Lebens…‹ auf Holzschindeln"
                attribution="David Franck"
              />
              <Paragraph>
                Die Hälfte seines Lebens hat der Dichter Friedrich Hölderlin
                (1770-1843) in jenem Tübinger Turm verbracht, der heute seinen
                Namen trägt. Die darin gezeigte Dauerausstellung widmet sich
                Hölderlins letzten 36 Lebensjahren im Turm, seinen Besuchern,
                der Schönheit seiner Turmgedichte und der Fürsorge, die er hier
                durch die Familie Zimmer empfing – und sie lässt die viel
                diskutierte Frage nach Hölderlins geistigem Zustand bewusst
                unbeantwortet. Sie beleuchtet aber auch Hölderlins Tübinger
                Studienjahre (1788–1793), in denen er erste Gedichte
                veröffentlichte und mit den späteren Philosophen Hegel und
                Schelling im Stift die revolutionären Ereignisse in Frankreich
                diskutierte.
              </Paragraph>
            </Grid>
          </Stack>

          <Stack>
            <Heading as="h2" level={2}>
              Arbeit an der Sprache – Inspiration für die Künste
            </Heading>
            <Grid columns={[1, 1, 2]}>
              <Image
                image={data.wirkung.childImageSharp.gatsbyImageData}
                alt="Bild des zweiten Raumes der Dauerausstellung: Mehrere weiße Vitrinen an den Wänden, in der Mitte eine Medienstation zur Wirkung Hölderlins in der Musik, der Literatur und den Künsten"
                attribution="David Franck"
              />
              <Paragraph>
                Über die in Tübingen entstandenen Gedichte hinaus blickt die
                Ausstellung auch auf Hölderlins ganzes literarisches Werk, mit
                dem er an den Grenzen der literarischen Sprache experimentiert
                hat. Zahlreiche Beispiele verdeutlichen, in welch hohem Maße
                Hölderlin Literatur, Musik und Kunst der nachfolgenden Epochen
                inspirierte. In wechselnden Sonderausstellungen zeigen
                Künstlerinnen und Künstler, Forscherinnen und Forscher,
                Leserinnen und Leser, dass diese Inspirationskraft nach wie vor
                Bestand hat.
              </Paragraph>
            </Grid>
          </Stack>

          <Stack>
            <Heading as="h2" level={2}>
              Literatur mit allen Sinnen
            </Heading>
            <Grid columns={[1, 1, 2]} reverse={true}>
              <Image
                image={data.sprachlabor.childImageSharp.gatsbyImageData}
                alt="Versmaß-Experimente an einer Magnettafel im Sprachlabor"
                attribution="David Franck"
              />
              <Paragraph>
                Der Sound und Rhythmus von Hölderlins Gedichten, die beim Lesen
                im Ohr hängen bleiben und sich dort einnisten, ziehen sich durch
                alle Räume der Ausstellung. Im dazugehörigen Sprachlabor kann
                jede Besucherin und jeder Besucher selbst mit Silben, Versen und
                Rhythmen experimentieren und dabei Hölderlins Arbeit an der
                Sprache nachverfolgen.
              </Paragraph>
            </Grid>
          </Stack>

          <Stack>
            <Divider size={4} />
            <Constrain>
              <AttributionDauerausstellung />
            </Constrain>
            <Divider size={4} />
          </Stack>

          <Stack>
            <Heading as="h2" level={2}>
              Media-Guide
            </Heading>
            <Grid columns={[1, 1, 2]}>
              <Image
                image={data.medienstation.childImageSharp.gatsbyImageData}
                alt="Bild einer Medienstation mit Ausschnitten aus Kompositionen zu Hölderlin"
                attribution="David Franck"
              />
              <Stack>
                <Paragraph>
                  Mit dem Media-Guide zur Dauerausstellung kann man sich einen
                  ganz eigenen Weg durch die Ausstellung bahnen: Raum für Raum
                  und Objekt für Objekt oder im Eildurchlauf.
                </Paragraph>
                <Paragraph>
                  Wer Lust hat, kann mithilfe des Media-Guides auf Hölderlins
                  Spuren eine Stadtführung unternehmen oder dem Tübinger
                  Literaturpfad folgen und andere literarische Orte in der Stadt
                  kennenlernen. Der Literaturpfad ist auch als{" "}
                  <TextLink href="https://www.tuebingen.de/literaturpfad">
                    App für iOS und Android verfügbar
                  </TextLink>
                  .
                </Paragraph>
              </Stack>
            </Grid>
          </Stack>

          <Box bg="muted" p={[6, 12]}>
            <Stack>
              <Stack space={6}>
                <Heading level={2}>Führungen</Heading>
                <Paragraph>
                  Zur Dauerausstellung gibt es ein vielfältiges Angebot aus
                  Führungen und Workshops. Ob ›Kurz und bündig‹ oder ›Einmal
                  alles‹, ob auf deutsch, englisch, französisch oder in leichter
                  Sprache – hier ist für jeden etwas dabei. Neben fest buchbaren
                  Formaten für Gruppen bieten wir jeden 1. und 3. Sonntag im
                  Monat auch eine öffentliche Führung um 14 Uhr an.
                </Paragraph>
              </Stack>
              <Actions>
                <Button to="/fuehrungen">Angebot für Alle</Button>
                <Button to="/fuer-schulen">Angebote für Schulen</Button>
              </Actions>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default IndexPage
